import { keyframes } from '@chakra-ui/system';
import { getColor, mode } from '@chakra-ui/theme-tools';
import { Dict } from '../types';

const fade = (startColor: string, endColor: string) =>
  keyframes({
    from: { borderColor: startColor, background: startColor },
    to: { borderColor: endColor, background: endColor },
  });

const baseStyle = (props: Dict) => {
  const defaultStartColor = mode('slate.50', 'slate.400')(props);
  const defaultEndColor = mode('slate.100', 'slate.500')(props);

  const { startColor = defaultStartColor, endColor = defaultEndColor, speed, theme } = props;

  const start = getColor(theme, startColor);
  const end = getColor(theme, endColor);

  return {
    opacity: 0.7,
    borderRadius: '2px',
    borderColor: start,
    background: end,
    animation: `${speed}s linear infinite alternate ${fade(start, end)}`,
  };
};

export const Skeleton = {
  baseStyle,
};
