import { mode } from '@chakra-ui/theme-tools';
import { Dict } from '../types';

const parts = ['container', 'control', 'label', 'icon'];

const baseStyleControl = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    h: 4.5,
    w: 4.5,
    transition: 'box-shadow 250ms',
    border: '1px solid',
    borderRadius: 'base',
    borderColor: 'inherit',
    color: 'transparent',
    fontSize: '0.75rem',
    _checked: {
      bg: mode(`${c}.500`, `${c}.200`)(props),
      borderColor: mode(`${c}.500`, `${c}.200`)(props),
      color: mode('white', 'slate.900')(props),

      _hover: {
        bg: mode(`${c}.600`, `${c}.300`)(props),
        borderColor: mode(`${c}.600`, `${c}.300`)(props),
      },
    },

    _indeterminate: {
      bg: mode(`${c}.500`, `${c}.200`)(props),
      borderColor: mode(`${c}.500`, `${c}.200`)(props),
      color: mode('white', 'slate.900')(props),
    },

    _focus: {
      boxShadow: 'focusVisible',
    },

    _invalid: {
      borderColor: mode('error.500', 'error.300')(props),
    },

    _disabled: {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  };
};

const baseStyleLabel = {
  userSelect: 'none',
  _disabled: { opacity: 0.5 },
};

const baseStyle = (props: Dict) => ({
  control: baseStyleControl(props),
  label: baseStyleLabel,
});

const sizes = {
  sm: {
    label: { textStyle: 'sm', marginStart: '0.5rem' },
  },
  md: {
    label: { textStyle: 'md', marginStart: '0.5rem' },
  },
  lg: {
    label: { textStyle: 'lg', marginStart: '0.75rem' },
  },
};

const defaultProps = {
  size: 'md',
  colorScheme: 'light-blue',
};

export const Checkbox = {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
