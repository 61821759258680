export const fonts = {
  body: 'Cera Pro',
  heading: 'Cera Pro',
  mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
};

export const fontWeights = {
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

export const fontSizes = {
  xs: '0.75rem', // -2
  sm: '0.875rem', // -1
  md: '1rem', // 0
  lg: '1.125rem', // 0.5
  xl: '1.25rem', // 1
  '2xl': '1.5625rem', // 2
  '3xl': '1.9375rem', // 3
  '4xl': '2.4375rem', // 4
  '5xl': '3.0625rem', // 5
  '6xl': '3.8125rem', // 6
  '7xl': '4.75rem', // 7
  '8xl': '5.9375rem', // 8
};

export const lineHeights = {
  xs: '1rem',
  sm: '1.25rem',
  md: '1.5rem',
  lg: '1.75rem',
  xl: '2rem',
  '2xl': '2.25rem',
  '3xl': '2.5rem',
  '4xl': '3rem',
  '5xl': '3.75rem',
  '6xl': '4.5rem',
  '7xl': '5.5rem',
  '8xl': '6.75rem',
};

// needed here because of the TypeScript
export const letterSpacings = {};
