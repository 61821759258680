import {
  chakra,
  forwardRef,
  HTMLChakraProps,
  SystemStyleObject,
  useStyles,
} from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';
import * as React from 'react';

export type IInlineNotificationContentProps = HTMLChakraProps<'div'>;

export const InlineNotificationContent = forwardRef<IInlineNotificationContentProps, 'div'>(
  (props, ref) => {
    const styles = useStyles();

    const contentStyles: SystemStyleObject = {
      display: 'inline',
      ...styles.content,
    };

    return (
      <chakra.div
        ref={ref}
        sx={{ gap: '8px' }}
        {...props}
        __css={contentStyles}
        className={cx('klik-inline-notification__content', props.className)}
      />
    );
  }
);
