import { ModalBody as ModalBodyComponent, ModalBodyProps } from '@chakra-ui/modal';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type IModalBodyProps = ModalBodyProps;

export const ModalBody = forwardRef<IModalBodyProps, 'div'>((props, ref) => {
  // Getting error: Expression produces a union type that is too complex to represent
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ModalBodyComponent {...props} ref={ref} />;
});
