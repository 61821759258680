import { transparentize } from '@chakra-ui/theme-tools';
import { Dict } from '../types';

/**
 * BaseStyles, avoid colors or any value that we want to have our end-users theme. we need to
 * discuss this with the designers. also try to use fully-written css props (don't use bg, use
 * backgroundColor, don't use h or w, use height and width) however, if you really want to use the
 * shorthand notation, group them together in "base" and "magic" categories
 */

const parts = ['root', 'tablist', 'tab', 'tabpanel', 'indicator'];

const sizes = {
  md: {
    tab: {
      paddingStart: '4', // units of 4
      paddingEnd: '4',
      height: '3rem',
      textStyle: 'lg',
    },
  },
  lg: {
    tab: {
      paddingStart: '8',
      paddingEnd: '8',
      height: '4rem',
      textStyle: 'xl',
    },
  },
};

const baseStyleTab = (props: Dict) => {
  const { isFitted } = props;

  return {
    fontWeight: 'regular',
    color: 'slate.600',
    flex: isFitted ? 1 : undefined,
    transition: 'colors normal',
    outline: 0,
    _focusVisible: {
      zIndex: 1,
      boxShadow: 'focusVisible',
    },
  };
};

const baseStyleTablist = (props: Dict) => {
  const { align = 'start', orientation } = props;

  const alignments = {
    end: 'flex-end',
    center: 'center',
    start: 'flex-start',
  };

  return {
    justifyContent: alignments[align as keyof typeof alignments],
    flexDirection: orientation === 'vertical' ? 'column' : 'row',
  };
};

const baseStyleTabpanel = { p: 4 };

const variantPlain = (props: Dict) => {
  const { colorScheme: c, theme, orientation } = props;
  const isVertical = orientation === 'vertical';
  const borderProp = orientation === 'vertical' ? 'borderLeft' : 'borderBottom';
  const marginProp = isVertical ? 'ml' : 'mb';

  return {
    tablist: {
      [borderProp]: '4px solid',
      borderColor: 'slate.100',
    },
    tab: {
      [borderProp]: '4px solid',
      borderColor: 'transparent',
      [marginProp]: '-4px',
      _selected: {
        fontWeight: 'medium',
        color: `${c}.500`,
        borderColor: 'currentColor',
        backgroundColor: `${c}.50`,
      },
      _active: {
        color: `${c}.700`,
      },
      _hover: {
        ':enabled': {
          backgroundColor: transparentize('slate.900', 0.05)(theme),
        },
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
  };
};

const baseStyleRoot = (props: Dict) => {
  const { orientation } = props;

  return {
    fontFamily: 'body',
    display: orientation === 'vertical' ? 'flex' : 'block',
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const baseStyle = (props: Dict) => ({
  root: baseStyleRoot(props),
  tab: baseStyleTab(props),
  tablist: baseStyleTablist(props),
  tabpanel: baseStyleTabpanel,
});

const variants = {
  plain: variantPlain,
};

const defaultProps = {
  variant: 'plain',
  size: 'md',
  colorScheme: 'light-blue',
};

export const Tabs = {
  parts,
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
