import {
  MenuButton as MenuButtonComponent,
  MenuButtonProps,
  useMenuContext,
} from '@chakra-ui/menu';
import { EventKeyMap } from '@chakra-ui/react-utils';
import { forwardRef } from '@chakra-ui/system';
import { callAllHandlers, cx, normalizeEventKey } from '@chakra-ui/utils';
import * as React from 'react';

export type IMenuButtonProps = MenuButtonProps;

export const MenuButton = forwardRef<IMenuButtonProps, 'div'>(
  ({ className, onClick: onClickProp, ...rest }, ref) => {
    const menu = useMenuContext();

    const { isOpen, onToggle, onClose } = menu;

    const handleClick = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onToggle();
      },
      [onToggle]
    );

    const handleKeyDown = React.useCallback(
      (e: React.KeyboardEvent<HTMLButtonElement>) => {
        const eventKey = normalizeEventKey(e);

        const closeIfOpen = isOpen ? onClose : undefined;

        const keyMap: EventKeyMap = {
          Enter: onToggle,
          // TODO If MenuButton is rendered as <a> as in Breadcrumb overflow story the click event doesn't fire as it does for a <button> element
          // Should we handle Space key explicitly in that scenario?
          // Space: onToggle,
          // ' ': onToggle,
          Escape: closeIfOpen,
          Tab: closeIfOpen,
        };

        const action = keyMap[eventKey];

        if (action) {
          e.preventDefault();
          e.stopPropagation();
          action(e);
        }
      },
      [onToggle, onClose, isOpen]
    );

    return (
      // eslint-disable-next-line react/jsx-no-bind
      <MenuButtonComponent
        {...rest}
        className={cx('klik-ui-menu__button', className)}
        onClick={callAllHandlers(onClickProp, handleClick)}
        onKeyDown={handleKeyDown}
        ref={ref}
      />
    );
  }
);
