/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { CSSObject } from '@chakra-ui/styled-system';
import { mode, transparentize } from '@chakra-ui/theme-tools';
import { Dict } from '../types';

const parts = ['container', 'buttons'];

const baseStyleContainer = (props: Dict): CSSObject => {
  return {};
};

const baseStyleButtons = (props: Dict): CSSObject => {
  const { colorScheme: c, theme } = props;

  return {
    _active: {
      bg: mode(`${c}.50`, 'slate.800')(props),
    },
    _hover: {
      bg: mode(
        transparentize('slate.900', 0.05)(theme),
        transparentize('slate.500', 0.5)(theme)
      )(props),
    },
    color: mode('slate.900', 'slate.100')(props),
  };
};

const baseStyle = (props: Dict) => ({
  container: baseStyleContainer(props),
  buttons: baseStyleButtons(props),
});

const defaultProps = {
  colorScheme: 'light-blue',
};

export const Pagination = {
  baseStyle,
  parts,
  defaultProps,
};
