import {
  FormErrorMessage as FormErrorMessageComponent,
  FormErrorMessageProps,
} from '@chakra-ui/form-control';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type IFormErrorMessageProps = FormErrorMessageProps;

export const FormErrorMessage = forwardRef<IFormErrorMessageProps, 'div'>((props, ref) => {
  return <FormErrorMessageComponent {...props} ref={ref} />;
});
