import { createContext } from '@chakra-ui/react-utils';
import { IStatus } from '@lego/klik-ui-status-utils';

export interface IModalContext {
  status?: IStatus;
  hideIcon?: boolean;
  size?: string;
}

export const [ModalProvider, useModalContext] = createContext<IModalContext>({
  name: 'ModalContext',
  errorMessage:
    'useModalContext: `context` is undefined. Seems you forgot to wrap Modal components in `<Modal />`',
});
