import { createContext } from '@chakra-ui/react-utils';

interface INavbarContext {
  isSecondary?: boolean;
  orientation?: 'horizontal' | 'vertical';
  isFullWidth?: boolean;
}

export const [NavbarProvider, useNavbarContext] = createContext<INavbarContext>({
  name: 'NavbarContext',
  errorMessage:
    'useNavbarContext: `context` is undefined. Seems you forgot to wrap the navbar items in `<NavbarContext />` ',
});
