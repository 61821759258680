import { ThemeComponentProps } from '@chakra-ui/theme';
import { textStyles } from '../textStyles';
import { Input } from './input';

const baseStyle = {
  display: 'block',
  ...Input.baseStyle.field,
};

const variants = {
  outline: (props: ThemeComponentProps) => Input.variants.outline(props).field,
};

const sizes = {
  sm: {
    ...textStyles.sm,
    minHeight: '72px',
    padding: '2',
  },
  md: {
    ...textStyles.md,
    padding: '3',
    minHeight: '92px',
  },
  lg: {
    ...textStyles.lg,
    minHeight: '112px',
    padding: '4',
  },
};

const defaultProps = {
  size: 'md',
  variant: 'outline',
};

export const Textarea = {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
