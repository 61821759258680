// from specifying responsiveSizes('3xl', )

import { fontSizes } from './foundations';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const responsiveSizes = (sizes: Array<keyof typeof fontSizes | undefined>) => {
  return {
    fontSize: sizes,
    lineHeight: sizes,
  };
};

export const textStyles = {
  // Our Default Type Scale, fully applied
  xs: { fontSize: 'xs', lineHeight: 'xs' }, // -2 - 12px / 16px
  sm: { fontSize: 'sm', lineHeight: 'sm' }, // -1 - 14px / 20px
  md: { fontSize: 'md', lineHeight: 'md' }, // 0 - 16px / 22px
  lg: { fontSize: 'lg', lineHeight: 'lg' }, // 0.5 - 18px / 24px
  xl: { fontSize: 'xl', lineHeight: 'xl' }, // 1 - 20px / 28px
  '2xl': { fontSize: '2xl', lineHeight: '2xl' }, // 2 - 25px / 32px
  '3xl': { fontSize: '3xl', lineHeight: '3xl' }, // 3 - 31px / 36px
  '4xl': { fontSize: '4xl', lineHeight: '4xl' }, // 4 - 39px / 45px
  '5xl': { fontSize: '5xl', lineHeight: '5xl' }, // 5 - 49px / 56px
  '6xl': { fontSize: '6xl', lineHeight: '6xl' }, // 6 - 61px / 70px
  '7xl': { fontSize: '7xl', lineHeight: '7xl' }, // 7 - 76px / 87px
  '8xl': { fontSize: '8xl', lineHeight: '8xl' }, // 8 - 95px / 109px

  // The Same TypeScale, but applied only to typography sets
  h1: responsiveSizes(['3xl', undefined, '4xl']),
  h2: responsiveSizes(['2xl', undefined, '3xl']),
  h3: responsiveSizes(['xl', undefined, '2xl']),
  h4: responsiveSizes(['lg', undefined, 'xl']),
  h5: {
    ...responsiveSizes(['md', undefined, 'md']),
    fontWeight: 'bold',
  },
  h6: {
    ...responsiveSizes(['md', undefined, 'md']),
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  body1: responsiveSizes(['md', undefined, 'md']),
  body2: responsiveSizes(['sm', undefined, 'sm']),
};
