import { getValidChildren } from '@chakra-ui/react-utils';
import {
  chakra,
  ComponentWithAs,
  forwardRef,
  HTMLChakraProps,
  omitThemingProps,
  StylesProvider,
  SystemStyleObject,
  ThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';
import { IStatus } from '@lego/klik-ui-status-utils';
import * as React from 'react';
import { InlineNotificationContent } from './InlineNotificationContent';
import { InlineNotificationDescription } from './InlineNotificationDescription';
import { InlineNotificationProvider } from './InlineNotificationProvider';
import { InlineNotificationStatus } from './InlineNotificationStatus';
import { InlineNotificationTitle } from './InlineNotificationTitle';
import { InlineNotificationWrapper } from './InlineNotificationWrapper';

interface IInlineNotificationOptions {
  /**
   * The status of the IInlineNotification
   */
  status?: IStatus;

  /**
   * Whether the inline nofication is embedded in your current page flow
   */
  isStandalone?: boolean;

  /**
   * With $status set, a corresponding icon is injected - unless explicitly hidden:
   */
  hideIcon?: boolean;
}

type IInlineNotificationComponent = ComponentWithAs<'div', IInlineNotificationProps> & {
  Content: typeof InlineNotificationContent;
  Description: typeof InlineNotificationDescription;
  /**
   * @deprecated don't use `InlineNotification.Status` anymore, it's built in
   */
  Status: typeof InlineNotificationStatus;
  Title: typeof InlineNotificationTitle;
};

export interface IInlineNotificationProps
  extends HTMLChakraProps<'div'>,
    IInlineNotificationOptions,
    ThemingProps<'InlineNotification'> {}

/**
 * Alert is used to communicate the state or status of a
 * page, feature or action
 */
export const InlineNotification = forwardRef<IInlineNotificationProps, 'div'>((props, ref) => {
  const { children, isStandalone = true, ...rest } = omitThemingProps(props);
  const validChildren = getValidChildren(children);

  const childrenFiltered = validChildren.filter((childNode) => {
    return childNode.type !== InlineNotificationStatus;
  });

  const styles = useMultiStyleConfig('InlineNotification', {
    ...props,
    isStandalone,
    colorScheme: props.colorScheme,
  });

  const alertStyles: SystemStyleObject = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    ...styles.container,
  };

  return (
    <InlineNotificationProvider
      value={{ isStandalone, status: props.variant as IStatus, hideIcon: props.hideIcon || false }}
    >
      <StylesProvider value={styles}>
        <chakra.div
          ref={ref}
          role="alert"
          {...rest}
          __css={alertStyles}
          className={cx('klik-inline-notification', props.className)}
        >
          <InlineNotificationWrapper>
            <InlineNotificationStatus />
            {childrenFiltered}
          </InlineNotificationWrapper>
        </chakra.div>
      </StylesProvider>
    </InlineNotificationProvider>
  );
}) as IInlineNotificationComponent;

InlineNotification.Content = InlineNotificationContent;
InlineNotification.Description = InlineNotificationDescription;
InlineNotification.Status = InlineNotificationStatus;
InlineNotification.Title = InlineNotificationTitle;
