import { __extends } from "tslib";
import { getSerdePlugin } from "@aws-sdk/middleware-serde";
import { Command as $Command } from "@aws-sdk/smithy-client";
import { PublishLayerVersionRequest, PublishLayerVersionResponse } from "../models/models_0";
import { deserializeAws_restJson1PublishLayerVersionCommand, serializeAws_restJson1PublishLayerVersionCommand, } from "../protocols/Aws_restJson1";
var PublishLayerVersionCommand = (function (_super) {
    __extends(PublishLayerVersionCommand, _super);
    function PublishLayerVersionCommand(input) {
        var _this = _super.call(this) || this;
        _this.input = input;
        return _this;
    }
    PublishLayerVersionCommand.prototype.resolveMiddleware = function (clientStack, configuration, options) {
        this.middlewareStack.use(getSerdePlugin(configuration, this.serialize, this.deserialize));
        var stack = clientStack.concat(this.middlewareStack);
        var logger = configuration.logger;
        var clientName = "LambdaClient";
        var commandName = "PublishLayerVersionCommand";
        var handlerExecutionContext = {
            logger: logger,
            clientName: clientName,
            commandName: commandName,
            inputFilterSensitiveLog: PublishLayerVersionRequest.filterSensitiveLog,
            outputFilterSensitiveLog: PublishLayerVersionResponse.filterSensitiveLog,
        };
        var requestHandler = configuration.requestHandler;
        return stack.resolve(function (request) {
            return requestHandler.handle(request.request, options || {});
        }, handlerExecutionContext);
    };
    PublishLayerVersionCommand.prototype.serialize = function (input, context) {
        return serializeAws_restJson1PublishLayerVersionCommand(input, context);
    };
    PublishLayerVersionCommand.prototype.deserialize = function (output, context) {
        return deserializeAws_restJson1PublishLayerVersionCommand(output, context);
    };
    return PublishLayerVersionCommand;
}($Command));
export { PublishLayerVersionCommand };
