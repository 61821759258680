import { CSSObject } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import { getCSSVar } from '@chakra-ui/utils';
import { Dict } from '../types';

const baseStyle = (props: Dict): CSSObject => {
  const { theme } = props;

  const backgroundColor = mode('slate.800', 'slate.300')(props);

  return {
    px: '4',
    py: '3',
    backgroundColor,
    '--popper-arrow-bg': getCSSVar(theme, 'colors', backgroundColor),
    color: mode('white', 'slate.900')(props),
    borderRadius: 'base',
    textStyle: 'body2',
    fontWeight: 'medium',
    boxShadow: '2',
    maxW: '320px',
    zIndex: 'tooltip',
  };
};

export const Tooltip = {
  baseStyle,
};
