import { BreadcrumbItem as BreadcrumbItemInternal, IBreadcrumbItemProps } from './BreadcrumbItem';
import { BreadcrumbLink as BreadcrumbLinkInternal, IBreadcrumbLinkProps } from './BreadcrumbLink';
import {
  BreadcrumbSeparator as BreadcrumbSeparatorInternal,
  IBreadcrumbSeparatorProps,
} from './BreadcrumbSeparator';

export * from './Breadcrumb';
export { IBreadcrumbItemProps, IBreadcrumbLinkProps, IBreadcrumbSeparatorProps };

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `Breadcrumb.Item` instead
 */
export const BreadcrumbItem = BreadcrumbItemInternal;
/**
 * @deprecated use `Breadcrumb.Link` instead
 */
export const BreadcrumbLink = BreadcrumbLinkInternal;
/**
 * @deprecated use `Breadcrumb.Separator` instead
 */
export const BreadcrumbSeparator = BreadcrumbSeparatorInternal;
