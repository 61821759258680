import { chakra, forwardRef, HTMLChakraProps, useStyles } from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';
import * as React from 'react';

export type IInlineNotificationTitleProps = HTMLChakraProps<'div'>;

export const InlineNotificationTitle = forwardRef<IInlineNotificationTitleProps, 'div'>(
  (props, ref) => {
    const styles = useStyles();

    return (
      <chakra.div
        ref={ref}
        {...props}
        __css={styles.title}
        className={cx('klik-inline-notification__title', props.className)}
      />
    );
  }
);
