/* eslint-disable @typescript-eslint/naming-convention */
import { IconButton, IIconButtonProps } from '@lego/klik-ui-icon-button';
import { CrossBold } from '@lego/klik-ui-icons';
import * as React from 'react';

export interface ICloseButtonProps extends Omit<IIconButtonProps, 'aria-label' | 'icon'> {
  /**
   * A11y: An optional label that describes the close button. Defaults to "Close".
   */
  'aria-label'?: string;
}

/**
 * A button with a close icon.
 *
 * It is used to handle the close functionality in feedback and overlay components
 * like Alerts, Toasts, Drawers and Modals.
 */
export const CloseButton: React.FunctionComponent<ICloseButtonProps> = (props) => {
  const { 'aria-label': ariaLabel = 'Close', ...rest } = props;
  const icon = <CrossBold color="slate.500" height="0.75rem" width="0.75rem" />;

  return (
    <IconButton
      aria-label={ariaLabel}
      icon={icon}
      mr="1"
      mt="1"
      size="md"
      variant="ghost"
      {...rest}
    />
  );
};
