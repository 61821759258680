import { ModalContent as ModalContentComponent, ModalContentProps } from '@chakra-ui/modal';
import { chakra, forwardRef, SystemStyleObject, useStyles } from '@chakra-ui/system';
import * as React from 'react';
import { ModalStatus } from './ModalStatus';

export type IModalContentProps = ModalContentProps;

export const ModalContent = forwardRef<IModalContentProps, 'section'>((props, ref) => {
  const { children } = props;

  const styles = useStyles();

  const outerwrapStyles: SystemStyleObject = {
    display: 'flex',
    flexDirection: 'row',
    ...styles.outer,
  };

  const innerwrapStyles: SystemStyleObject = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  };

  return (
    <ModalContentComponent {...props} ref={ref}>
      <chakra.div className="klik-modal-content-outer" __css={outerwrapStyles}>
        <ModalStatus />
        <chakra.div className="klik-modal-content-inner" __css={innerwrapStyles}>
          {children}
        </chakra.div>
      </chakra.div>
    </ModalContentComponent>
  );
});
