import { Icon, IconProps } from '@chakra-ui/icon';
import { chakra, forwardRef, keyframes, SystemStyleObject } from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';
import * as React from 'react';

export type ISpinnerInlineProps = IconProps;

const Circle = (props: {
  radius: number;
  posx: number;
  posy: number;
  animationDelayInMs: number;
}) => {
  const { radius, posx, posy, animationDelayInMs } = props;

  const css: SystemStyleObject = {
    '--spinner-inline-circle-posx': `${posx}px`,
    '--spinner-inline-circle-posy': `${posy}px`,
    '--animation-delay': `${animationDelayInMs}ms`,
  };

  return <chakra.circle __css={css} r={radius} />;
};

export const SpinnerInline = forwardRef<ISpinnerInlineProps, 'svg'>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,  @typescript-eslint/naming-convention
  const { className, css: _css, ...rest } = props;

  const viewBoxSize = 36;
  const viewBoxCenter = viewBoxSize / 2;
  const svgViewBox = `0 0 ${viewBoxSize} ${viewBoxSize}`;
  const circleRadius = 7;
  const circleCenterLeft = circleRadius;
  const circleCenterRight = viewBoxSize - circleRadius;
  const circleCenterTop = circleRadius;
  const circleCenterBottom = viewBoxSize - circleRadius;
  const circleAnimationDelayInMs = 200;

  const circleInitPosition =
    'translate(var(--spinner-inline-circle-posx),var(--spinner-inline-circle-posy))';

  const circleAnim = (() => {
    const circleCenterPosition = `translate(${viewBoxCenter}px, ${viewBoxCenter}px)`;
    const animationTimingFunction = 'cubic-bezier(1, 0.005, 0.52, 0.99)';
    const animationDuration = '1600ms';

    const frames = keyframes({
      '25%': {
        transform: `${circleCenterPosition} scale(0.2)`,
      },
      '60%': {
        transform: circleInitPosition,
      },
    });

    return `${frames} ${animationDuration} ${animationTimingFunction} infinite normal forwards`;
  })();

  const spinnerStyles: SystemStyleObject = {
    stroke: 'none',
    fill: 'currentColor',
    circle: {
      transform: circleInitPosition,
      animation: circleAnim,
      animationDelay: 'var(--animation-delay)',
    },
  };

  const circles = [
    {
      posx: circleCenterRight,
      posy: circleCenterBottom,
    },
    {
      posx: circleCenterRight,
      posy: circleCenterTop,
    },
    {
      posx: circleCenterLeft,
      posy: circleCenterTop,
    },
    {
      posx: circleCenterLeft,
      posy: circleCenterBottom,
    },
  ];

  return (
    <Icon
      __css={spinnerStyles}
      className={cx('klik-ui-spinner-inline', className)}
      ref={ref}
      viewBox={svgViewBox}
      {...rest}
    >
      {circles.map((circle, index) => (
        <Circle
          animationDelayInMs={circleAnimationDelayInMs * index}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          posx={circle.posx}
          posy={circle.posy}
          radius={circleRadius}
        />
      ))}
    </Icon>
  );
});

SpinnerInline.displayName = 'SpinnerInline';
