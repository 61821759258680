import {
  IInputAddonProps,
  InputLeftAddon as InputLeftAddonInternal,
  InputRightAddon as InputRightAddonInternal,
} from './InputAddon';
import {
  IInputElementProps,
  InputLeftElement as InputLeftElementInternal,
  InputRightElement as InputRightElementInternal,
} from './InputElement';
import { IInputGroupProps, InputGroup as InputGroupInternal } from './InputGroup';

export * from './Input';
export { IInputAddonProps, IInputGroupProps, IInputElementProps };

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `Input.LeftAddon` instead
 */
export const InputLeftAddon = InputLeftAddonInternal;
/**
 * @deprecated use `Input.RightAddon` instead
 */
export const InputRightAddon = InputRightAddonInternal;
/**
 * @deprecated use `Input.LeftElement` instead
 */
export const InputLeftElement = InputLeftElementInternal;
/**
 * @deprecated use `Input.RightElement` instead
 */
export const InputRightElement = InputRightElementInternal;
/**
 * @deprecated use `Input.Group` instead
 */
export const InputGroup = InputGroupInternal;
