import {
  chakra,
  forwardRef,
  HTMLChakraProps,
  omitThemingProps,
  ThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/system';
import * as React from 'react';
import { useNavbarContext } from './NavbarProvider';

export interface INavbarSegmentProps extends HTMLChakraProps<'div'>, ThemingProps<'Navbar'> {}

export const NavbarSegment = forwardRef<INavbarSegmentProps, 'div'>((props, ref) => {
  const { ...rest } = omitThemingProps(props);

  const { orientation } = useNavbarContext();
  const { segment: styles } = useMultiStyleConfig('Navbar', { ...props, orientation });

  return <chakra.div __css={styles} ref={ref} {...rest} />;
});
