import { textStyles } from '../textStyles';

const parts = ['container', 'item', 'icon', 'content'];

const baseStyleContainer = {};

const baseStyleItem = {
  ...textStyles.lg,
};

const baseStyleContent = {
  display: 'inline-flex',
};

const baseStyleIcon = {
  marginEnd: '0.5rem',
  display: 'inline',
  verticalAlign: 'text-bottom',
};

const baseStyle = {
  container: baseStyleContainer,
  item: baseStyleItem,
  icon: baseStyleIcon,
  content: baseStyleContent,
};

export const List = {
  parts,
  baseStyle,
};
