import { chakra, HTMLChakraProps, SystemStyleObject, useStyles } from '@chakra-ui/system';
import { StatusMap } from '@lego/klik-ui-status-utils';
import * as React from 'react';
import { useModalContext } from './ModalProvider';

export type IModalStatusProps = HTMLChakraProps<'div'>;

export const ModalStatus: React.FunctionComponent<IModalStatusProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { status, hideIcon } = useModalContext();

  const styles = useStyles();

  if (status === 'plain' || status === undefined) {
    // eslint-disable-next-line no-null/no-null
    return null;
  }

  if (hideIcon) {
    // eslint-disable-next-line no-null/no-null
    return null;
  }

  const { icon: BaseIcon } = StatusMap[status];

  const colorIndex = status === 'error' ? '500' : '400';
  const colorName = status === 'info' ? 'information' : status;

  const wrapperStyles: SystemStyleObject = {
    color: `${colorName}.${colorIndex}`,
    ...styles.status,
  };

  return (
    <chakra.div display="inherit" {...props} __css={wrapperStyles}>
      <BaseIcon />
    </chakra.div>
  );
};
