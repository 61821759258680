/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { CSSObject } from '@chakra-ui/styled-system';
import { mode, transparentize } from '@chakra-ui/theme-tools';
import { Dict } from '../types';

const baseStyle = (props: Dict): CSSObject => {
  const { theme, hasBorder } = props;

  return {
    _hover: {
      bg: mode('slate.30', 'slate.800')(props),
      borderColor: mode(transparentize('slate.900', 0.3)(theme), 'slate.700')(props),
      boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)',
    },
    bg: mode('white', 'slate.800')(props),
    borderColor: hasBorder
      ? mode(transparentize('slate.900', 0.2)(theme), 'slate.700')(props)
      : 'transparent',
    borderRadius: 'base',
    borderWidth: 1,
    flexDirection: 'column',
  };
};

export const Well = {
  baseStyle,
};
