/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { mode, transparentize } from '@chakra-ui/theme-tools';
import { fontSizes } from '../foundations';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dict = Record<string, any>;

const parts = ['track', 'filledTrack'];

const baseStyleTrack = (props: Dict) => {
  const { theme } = props;

  return {
    stroke: mode(
      transparentize('slate.900', 0.1)(theme),
      transparentize('slate.500', 0.5)(theme)
    )(props),
  };
};

const baseStyleFilledTrack = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    stroke: mode(`${c}.500`, `white`)(props),
  };
};

const baseStyle = (props: Dict) => ({
  filledTrack: baseStyleFilledTrack(props),
  track: baseStyleTrack(props),
});

const sizes = {
  sm: {
    track: {
      height: 10,
      width: 10,
    },
    label: {
      fontSize: fontSizes.xs,
    },
  },
  md: {
    track: {
      height: 15,
      width: 15,
    },
    label: {
      fontSize: fontSizes.md,
    },
  },
  lg: {
    track: {
      height: 25,
      width: 25,
    },
    label: {
      fontSize: fontSizes['2xl'],
    },
  },
};

const defaultProps = {
  size: 'md',
  colorScheme: 'light-blue',
};

export const CircularProgress = {
  parts,
  sizes,
  baseStyle,
  defaultProps,
};
