import { mode, transparentize } from '@chakra-ui/theme-tools';
import { Dict } from '../types';

const parts = ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'];

/**
 * TODO: refactor this
 * Since the `maxWidth` prop references theme.sizes internally,
 * we can leverage that to size our modals.
 */
const getSize = (value: string) => {
  if (value === 'full') {
    return { dialog: { maxW: '100vw', h: '100vh' } };
  }

  return { dialog: { maxW: value } };
};

const baseStyleOverlay = (props: Dict) => {
  const { colorScheme: c, theme } = props;

  return {
    backgroundColor: transparentize(`${c}.900`, 0.8)(theme),
    zIndex: 'overlay',
  };
};

const baseStyleDialogContainer = {
  display: 'flex',
  zIndex: 'modal',
  justifyContent: 'center',
};

const baseStyleDialog = (props: Dict) => {
  const { isFullHeight } = props;
  const { colorScheme: c } = props;

  const baseBg = c === 'white' ? 'white' : `${c}.500`;
  const baseBgDark = c === 'white' ? 'slate.700' : `${c}.500`;

  return {
    ...(isFullHeight && { height: '100vh' }),
    zIndex: 'modal',
    maxH: '100vh',
    backgroundColor: mode(baseBg, baseBgDark)(props),
    color: 'inherit',
    boxShadow: mode('lg', 'dark-lg')(props),
  };
};

const baseStyleHeader = {
  px: 6,
  py: 4,
  textStyle: 'h4',
};

const baseStyleCloseButton = {
  position: 'absolute',
  top: 6,
  insetEnd: 4,
};

const baseStyleBody = {
  px: 6,
  py: 2,
  flex: 1,
  overflow: 'auto',
};

const baseStyleFooter = {
  px: 6,
  py: 4,
};

const baseStyle = (props: Dict) => ({
  overlay: baseStyleOverlay(props),
  dialogContainer: baseStyleDialogContainer,
  dialog: baseStyleDialog(props),
  header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
  body: baseStyleBody,
  footer: baseStyleFooter,
});

const sizes = {
  xs: getSize('xs'),
  sm: getSize('md'),
  md: getSize('lg'),
  lg: getSize('2xl'),
  xl: getSize('4xl'),
  full: getSize('full'),
};

const defaultProps = {
  size: 'xs',
  colorScheme: 'white',
};

export const Drawer = {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
