import { ThemeComponentProps } from '@chakra-ui/theme';
import { getColor, mode, transparentize } from '@chakra-ui/theme-tools';
import { textStyles } from '../textStyles';

const parts = ['field', 'addon'];

const baseStyle = {
  field: {
    width: '100%',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transition: 'all 0.2s',
    borderRadius: 'base',
    boxShadow: '-2',
  },
};

const size = {
  lg: {
    ...textStyles.lg,
    paddingX: '4',
    h: '16',
  },

  md: {
    ...textStyles.md,
    paddingX: '3',
    h: '12',
  },

  sm: {
    ...textStyles.sm,
    paddingX: '2',
    h: '8',
  },
};

const sizes = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
};

const getDefaults = (props: ThemeComponentProps) => {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;

  return {
    focusBorderColor: fc || mode('information.400', 'information.400')(props),
    errorBorderColor: ec || mode('error.400', 'error.400')(props),
  };
};

const variantOutline = (props: ThemeComponentProps) => {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      border: '1px solid',
      borderColor: 'inherit',
      bg: 'white',
      _disabled: {
        borderColor: 'inherit !important',
        opacity: 0.5,
        boxShadow: 'none !important',
        cursor: 'not-allowed',
        bg: 'slate.30',
      },
      _hover: {
        borderColor: mode('slate.300', transparentize('slate.900', 0.05)(props))(props),
        boxShadow: `0 0 0 1px ${getColor(theme, 'slate.300')}`,
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _invalid: {
        borderColor: getColor(theme, ec),
        boxShadow: `0 0 0 1px ${getColor(theme, ec)}`,
      },
      _focus: {
        zIndex: 1,
        borderColor: getColor(theme, fc),
        boxShadow: `0 0 0 1px ${getColor(theme, fc)}`,
      },
    },
    addon: {
      border: '1px solid',
      borderRadius: 'base',
      color: 'slate.500',
      borderColor: mode('inherit', transparentize('slate.900', 0.05)(props))(props),
    },
  };
};

const variantUnstyled = {
  field: {
    bg: 'transparent',
    pl: 0,
    pr: 0,
    height: 'auto',
  },
  addon: {
    bg: 'transparent',
    pl: 0,
    pr: 0,
    height: 'auto',
  },
};

const variants = {
  outline: variantOutline,
  unstyled: variantUnstyled,
};

const defaultProps = {
  size: 'md',
  variant: 'outline',
};

export const Input = {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
