import { mode } from '@chakra-ui/theme-tools';
import { mergeWith as merge } from '@chakra-ui/utils';
import { Input } from './input';

const parts = ['field', 'icon'];

const baseStyleField = (props: Record<string, any>) => {
  return {
    appearance: 'none',
    paddingBottom: '1px',
    lineHeight: 'normal',
    width: '100%',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    transition: 'all 0.2s',
    boxShadow: '1',
    borderRadius: 'base',
    '> option, > optgroup': {
      bg: mode('white', 'slate.700')(props),
    },
  };
};

const baseStyleIcon = {
  width: '1.5rem',
  height: '100%',
  insetEnd: '0.5rem',
  position: 'relative',
  color: 'currentColor',
  fontSize: '1.25rem',
  _disabled: {
    opacity: 0.5,
  },
};

const baseStyle = (props: Record<string, any>) => ({
  field: baseStyleField(props),
  icon: baseStyleIcon,
});

const sizes = merge({}, Input.sizes, {
  xs: {
    icon: {
      insetEnd: '0.25rem',
    },
  },
});

export const Select = {
  parts,
  baseStyle,
  sizes,
  variants: Input.variants,
  defaultProps: Input.defaultProps,
};
