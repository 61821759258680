import { ComponentWithAs, forwardRef } from '@chakra-ui/system';
import { Tag as TagComponent, TagProps } from '@chakra-ui/tag';
import * as React from 'react';
import { TagCloseButton } from './TagCloseButton';
import { TagLabel } from './TagLabel';

export type ITagProps = TagProps;

type ITagComponent = ComponentWithAs<'span', ITagProps> & {
  Label: typeof TagLabel;
  CloseButton: typeof TagCloseButton;
};

export const Tag = forwardRef<ITagProps, 'span'>((props, ref) => {
  return <TagComponent {...props} ref={ref} />;
}) as ITagComponent;

Tag.Label = TagLabel;
Tag.CloseButton = TagCloseButton;
