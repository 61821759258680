import { Icon as IconComponent, IconProps } from '@chakra-ui/icon';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type IIconProps = IconProps;

export const Icon = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', css: _css, ...rest }, ref) => (
    <IconComponent viewBox={viewBox} {...rest} ref={ref} />
  )
);
