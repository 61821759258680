import {
  chakra,
  ComponentWithAs,
  forwardRef,
  HTMLChakraProps,
  omitThemingProps,
  StylesProvider,
  ThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/system';
import * as React from 'react';
import { NavbarBrand } from './NavbarBrand';
import { NavbarBurger } from './NavbarBurger';
import { MenuOption, NavbarMenuItem } from './NavbarMenuItem';
import { NavbarProvider } from './NavbarProvider';
import { NavbarSegment } from './NavbarSegment';

type NavbarComponent = ComponentWithAs<'nav', INavbarProps> & {
  Brand: typeof NavbarBrand;
  Burger: typeof NavbarBurger;
  Item: typeof NavbarMenuItem;
  Option: typeof MenuOption;
  Segment: typeof NavbarSegment;
};

interface INavbarOptions {
  isSecondary?: boolean;
  orientation?: 'horizontal' | 'vertical';
  isFullWidth?: boolean;
}

export interface INavbarProps
  extends HTMLChakraProps<'nav'>,
    INavbarOptions,
    ThemingProps<'Navbar'> {}

export const Navbar = forwardRef<INavbarProps, 'nav'>((props, ref) => {
  const { isSecondary, orientation, isFullWidth, ...rest } = omitThemingProps(props);

  const styles = useMultiStyleConfig('Navbar', props);

  return (
    <NavbarProvider value={{ isSecondary, orientation, isFullWidth }}>
      <StylesProvider value={styles}>
        <chakra.nav __css={styles.container} ref={ref} {...rest} />
      </StylesProvider>
    </NavbarProvider>
  );
}) as NavbarComponent;

Navbar.Brand = NavbarBrand;
Navbar.Burger = NavbarBurger;
Navbar.Item = NavbarMenuItem;
Navbar.Option = MenuOption;
Navbar.Segment = NavbarSegment;
