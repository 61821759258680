import { mode } from '@chakra-ui/theme-tools';
import { Dict } from '../types';
import { Checkbox } from './checkbox';

const parts = ['container', 'control', 'label'];

const baseStyleControl = (props: Dict) => {
  const { control } = Checkbox.baseStyle(props);
  const { colorScheme: c } = props;

  return {
    ...control,
    w: 4.5,
    h: 4.5,
    borderRadius: 'full',
    cursor: 'pointer',
    _checked: {
      ...control._checked,
      borderWidth: '2px',
      backgroundColor: 'white',
      _before: {
        content: `""`,
        display: 'inline-block',
        pos: 'relative',
        w: 2.5,
        h: 2.5,
        borderRadius: '50%',
        backgroundColor: mode(`${c}.500`, `${c}.200`)(props),
      },
      _hover: {
        backgroundColor: 'white',
        _before: {
          backgroundColor: mode(`${c}.600`, `${c}.300`)(props),
        },
      },
      _disabled: {
        cursor: 'not-allowed',
        backgroundColor: 'white',
        _before: {
          backgroundColor: mode(`${c}.500`, `${c}.200`)(props),
        },
      },
    },
  };
};

const baseStyle = (props: Dict) => ({
  label: Checkbox.baseStyle(props).label,
  control: baseStyleControl(props),
});

const sizes = {
  sm: {
    label: { textStyle: 'sm' },
  },
  md: {
    label: { textStyle: 'md' },
  },
  lg: {
    label: { textStyle: 'lg' },
  },
};

const defaultProps = {
  size: 'md',
  colorScheme: 'light-blue',
};

export const Radio = {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
