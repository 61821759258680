import { chakra, HTMLChakraProps, useStyles } from '@chakra-ui/system';
import * as React from 'react';

export type IStepLabelProps = HTMLChakraProps<'label'> & {
  index?: number;
};

export const StepLabel: React.FunctionComponent<IStepLabelProps> = ({
  index,
  children,
  ...props
}) => {
  const styles = useStyles();

  return (
    <chakra.label __css={styles.label} {...props}>
      {children}
    </chakra.label>
  );
};
