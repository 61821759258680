import { IStepProps, Step as StepInternal } from './Step';
import { IStepLabelProps, StepLabel as StepLabelInternal } from './StepLabel';
import { IStepPanelProps, StepPanel as StepPanelInternal } from './StepPanel';

export * from './Stepper';
export { IStepProps, IStepLabelProps, IStepPanelProps };

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `Stepper.Step` instead
 */
export const Step = StepInternal;
/**
 * @deprecated use `Stepper.Label` instead
 */
export const StepLabel = StepLabelInternal;
/**
 * @deprecated use `Stepper.Panel` instead
 */
export const StepPanel = StepPanelInternal;
