import { createContext } from '@chakra-ui/react-utils';
import { IStatus } from '@lego/klik-ui-status-utils';

export interface IInlineNotificationContext {
  status: IStatus;
  isStandalone: boolean;
  hideIcon?: boolean;
}

export const [InlineNotificationProvider, useInlineNotificationContext] =
  createContext<IInlineNotificationContext>({
    name: 'InlineNotificationContext',
    errorMessage:
      'useInlineNotificationContext: `context` is undefined. Seems you forgot to wrap InlineNotification components in `<InlineNotification />`',
  });
