/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ColorMode } from '@chakra-ui/color-mode';
import { ThemeConfig } from '@chakra-ui/theme';
import * as components from './components';
import * as foundations from './foundations';
import { globalStyles } from './globalStyles';
import { textStyles } from './textStyles';

const config: ThemeConfig = {
  // TODO: switch to true when darkmode is implemented
  useSystemColorMode: false,
  initialColorMode: 'light' as ColorMode,
};

const direction: 'ltr' | 'rtl' = 'ltr';

/**
 * do not change the named export to anything else than theme, @chakra-ui/cli uses the named export
 * to identify when you want to overwrite the theming defintions with your own.
 */
export const theme = {
  direction,
  transition: {
    property: {},
    easing: {},
    duration: {},
  },
  ...foundations,
  components: {
    ...components,
  },
  styles: globalStyles,
  textStyles,
  config,
};

export type Theme = typeof theme;
