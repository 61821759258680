import {
  IInlineNotificationContentProps,
  InlineNotificationContent as InlineNotificationContentInternal,
} from './InlineNotificationContent';
import {
  IInlineNotificationDescriptionProps,
  InlineNotificationDescription as InlineNotificationDescriptionInternal,
} from './InlineNotificationDescription';
import {
  IInlineNotificationStatusProps,
  InlineNotificationStatus as InlineNotificationStatusInternal,
} from './InlineNotificationStatus';
import {
  IInlineNotificationTitleProps,
  InlineNotificationTitle as InlineNotificationTitleInternal,
} from './InlineNotificationTitle';

export * from './InlineNotification';
export {
  IInlineNotificationTitleProps,
  IInlineNotificationStatusProps,
  IInlineNotificationContentProps,
  IInlineNotificationDescriptionProps,
};

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `InlineNotification.Content` instead
 */
export const InlineNotificationContent = InlineNotificationContentInternal;
/**
 * @deprecated use `InlineNotification.Description` instead
 */
export const InlineNotificationDescription = InlineNotificationDescriptionInternal;
/**
 * @deprecated use `InlineNotification.Status` instead
 */
export const InlineNotificationStatus = InlineNotificationStatusInternal;
/**
 * @deprecated use `InlineNotification.Title` instead
 */
export const InlineNotificationTitle = InlineNotificationTitleInternal;
