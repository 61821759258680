import { mode, transparentize } from '@chakra-ui/theme-tools';
import { Dict } from '../types';

const parts = ['table', 'thead', 'tbody', 'tr', 'th', 'td', 'caption'];

const baseStyle = (props: Dict) => {
  return {
    table: {
      fontVariantNumeric: 'lining-nums tabular-nums',
      borderCollapse: 'collapse',
      width: 'full',
      tableLayout: 'fixed',
    },
    th: {
      backgroundColor: mode(transparentize('slate.900', 0.05), 'slate.800')(props),
      fontFamily: 'heading',
      fontWeight: 'bold',
      textAlign: 'left',
    },
    td: {
      textAlign: 'left',
    },
    caption: {
      mt: 4,
      fontFamily: 'heading',
      textAlign: 'center',
      fontWeight: 'medium',
    },
  };
};

const numericStyles = {
  '&[data-is-numeric=true]': {
    textAlign: 'right',
  },
};

const simpleVariant = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    th: {
      color: mode('slate.900', 'slate.200')(props),
      borderBottom: '2px',
      borderColor: mode('slate.100', 'slate.400')(props),
      ...numericStyles,
    },
    tr: {
      _selected: {
        backgroundColor: mode(`${c}.10`, 'slate.200')(props),
      },
    },
    td: {
      borderBottom: '1px',
      borderColor: mode('slate.100', 'slate.400')(props),
      ...numericStyles,
    },
    caption: {
      color: mode('slate.900', 'slate.200')(props),
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
};

const variants = {
  simple: simpleVariant,
  unstyled: {},
};

const sizes = {
  sm: {
    th: {
      px: '0.75rem',
      py: '0.5rem',
      textStyle: 'h5',
    },
    td: {
      px: '0.75rem',
      py: '0.5rem',
      textStyle: 'body2',
    },
    caption: {
      px: '0.75rem',
      py: '0.5rem',
      textStyle: 'body2',
    },
  },
  md: {
    th: {
      px: '1rem',
      py: '0.75rem',
      textStyle: 'h5',
    },
    td: {
      px: '1rem',
      py: '0.75rem',
      textStyle: 'body1',
    },
    caption: {
      px: '1rem',
      py: '0.75rem',
      textStyle: 'body2',
    },
  },
};

const defaultProps = {
  variant: 'simple',
  size: 'md',
  colorScheme: 'slate',
};

export const Table = {
  parts,
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
