import { Stack as StackComponent, StackProps } from '@chakra-ui/layout';
import { ComponentWithAs, forwardRef } from '@chakra-ui/system';
import * as React from 'react';
import { StackDivider } from './StackDivider';

export type IStackProps = StackProps;

type IStackComponent = ComponentWithAs<'div', IStackProps> & {
  Divider: typeof StackDivider;
};

export const Stack = forwardRef<IStackProps, 'div'>((props, ref) => {
  return <StackComponent {...props} ref={ref} />;
}) as IStackComponent;

Stack.Divider = StackDivider;
