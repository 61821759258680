import { Button as ButtonComponent, ButtonProps } from '@chakra-ui/button';
import { forwardRef } from '@chakra-ui/system';
import { dataAttr } from '@chakra-ui/utils';
import { SpinnerInline } from '@lego/klik-ui-spinner-inline';
import * as React from 'react';

export type IButtonProps = ButtonProps;

export const Button = forwardRef<IButtonProps, 'button'>((props, ref) => {
  const { loadingText } = props;
  const isSpinnerOnly = !loadingText;

  return (
    <ButtonComponent
      tabIndex={0}
      {...props}
      data-spinneronly={dataAttr(isSpinnerOnly)}
      ref={ref}
      spinner={<SpinnerInline />}
    />
  );
});
