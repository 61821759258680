import { Link as LinkComponent, LinkProps } from '@chakra-ui/layout';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export interface ILinkProps extends LinkProps {
  /* Whether this is a link that is used inline in a sentence */
  isInline?: boolean;
}

export const Link = forwardRef<ILinkProps, 'a'>(
  ({ isInline = false, variant = 'standalone', ...rest }, ref) => {
    const variantOverride = isInline ? 'inline' : variant;

    return <LinkComponent variant={variantOverride} {...rest} ref={ref} />;
  }
);
