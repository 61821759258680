/**
 * Spaces are in scales of the equivalent of 4px.
 */
export const space = {
  0: '0',
  '0.5': '0.125rem', // 2px
  '1': '0.25rem', // 4px
  '1.5': '0.375rem', // 6px
  '2': '0.5rem', // 8px
  '2.5': '0.625rem', // 10px
  '3': '0.75rem', // 12px
  '3.5': '0.875rem', // 14px
  '4': '1rem', // 16px
  '4.5': '1.125rem', // 18px
  '5': '1.25rem', // 20px
  '5.5': '1.375rem', // 22px
  '6': '1.5rem', // 24px
  '6.5': '1.625rem', // 26px
  '7': '1.75rem', // 28px
  '7.5': '1.875rem', // 30px
  '8': '2rem', // 32px
  '8.5': '2.125rem', // 34px
  '9': '2.25rem', // 36px
  '9.5': '2.375rem', // 38px
  '10': '2.5rem', // 40px
  '10.5': '2.625rem', // 42px
  '11': '2.75rem', // 44px
  '11.5': '2.875rem', // 46px
  '12': '3rem', // 48px
  '12.5': '3.125rem', // 50px
  '13': '3.25rem', // 52px
  '13.5': '3.375rem', // 54px
  '14': '3.5rem', // 56px
  '14.5': '3.625rem', // 58px
  '15': '3.75rem', // 60px
  '15.5': '3.875rem', // 62px
  '16': '4rem', // 64px
  '16.5': '4.125rem', // 66px
  '17': '4.25rem', // 68px
  '17.5': '4.375rem', // 70px
  '18': '4.5rem', // 72px
  '18.5': '4.625rem', // 74px
  '19': '4.75rem', // 76px
  '19.5': '4.875rem', // 78px
  '20': '5rem', // 80px
  '20.5': '5.125rem', // 82px
  '21': '5.25rem', // 84px
  '21.5': '5.375rem', // 86px
  '22': '5.5rem', // 88px
  '22.5': '5.625rem', // 90px
  '23': '5.75rem', // 92px
  '23.5': '5.875rem', // 94px
  '24': '6rem', // 96px
  '24.5': '6.125rem', // 98px
  '25': '6.25rem', // 100px
  '25.5': '6.375rem', // 102px
  '26': '6.5rem', // 104px
  '26.5': '6.625rem', // 106px
  '27': '6.75rem', // 108px
  '27.5': '6.875rem', // 110px
  '28': '7rem', // 112px
  '28.5': '7.125rem', // 114px
  '29': '7.25rem', // 116px
  '29.5': '7.375rem', // 118px
  '30': '7.5rem', // 120px
  '30.5': '7.625rem', // 122px
  '31': '7.75rem', // 124px
  '31.5': '7.875rem', // 126px
  '32': '8rem', // 128px
  '32.5': '8.125rem', // 130px
  '33': '8.25rem', // 132px
  '33.5': '8.375rem', // 134px
  '34': '8.5rem', // 136px
  '34.5': '8.625rem', // 138px
  '35': '8.75rem', // 140px
  '35.5': '8.875rem', // 142px
  '36': '9rem', // 144px
  '36.5': '9.125rem', // 146px
  '37': '9.25rem', // 148px
  '37.5': '9.375rem', // 150px
  '38': '9.5rem', // 152px
  '38.5': '9.625rem', // 154px
  '39': '9.75rem', // 156px
  '39.5': '9.875rem', // 158px
  '40': '10rem', // 160px
};
