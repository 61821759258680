import { createContext, HTMLProps } from '@chakra-ui/react-utils';

type StepperContext = {
  activeStep: number;
  completedSteps: number[];
  readonlySteps?: number[];
  alignment?: 'center' | 'left' | 'right';
  orientation?: 'horizontal' | 'vertical';
};

export const [StepperProvider, useStepperContext] = createContext<StepperContext>({
  name: 'StepperContext',
  errorMessage:
    'useStepperContext: `context` is undefined. Seems you forgot to wrap the stepper item parts in `<StepperContext  />` ',
});

/**
 * useStepper
 * get aria attributes for the stepper elements
 *
 * @memberof stepper
 * @author Simon Groenborg
 */

type UseSteppProps = {
  panelId?: string;
  buttonId?: string;
  isOpen?: boolean;
  disabled?: boolean;
};

type UseStepActions = {
  getButtonProps: () => HTMLProps;
  getPanelProps: () => HTMLProps;
};

export const useStep = (props: UseSteppProps): UseStepActions => {
  const getPanelProps = (): HTMLProps => {
    return {
      role: 'region',
      id: props.panelId,
      'aria-labelledby': props.buttonId,
    };
  };

  const getButtonProps = (): HTMLProps => {
    return {
      id: props.panelId,
      'aria-expanded': props.isOpen,
      'aria-controls': props.panelId,
    };
  };

  return {
    getPanelProps,
    getButtonProps,
  };
};
