import { Dict } from '../types';

const parts = ['container', 'step', 'link', 'graph', 'panel', 'label'];

const hGraph = {
  content: '""',
  display: 'block',
  flexGrow: 1,
  height: '1',
};

const vGraph = {
  borderRadius: '10px',
  marginTop: '2',
  content: '""',
  display: 'block',
  flexGrow: 1,
  minHeight: '6',
  width: '1',
};

const containerAndLabelStyles = {
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    textAlign: 'center',
  },
  label: {
    marginTop: '4',
  },
};

const baseStyleContainer = (props: Dict) => {
  return {
    listStyleType: 'none',
    'li:first-child .step-graph::before': {
      visibility: 'hidden',
    },
    'li:last-child .step-graph::after': {
      visibility: 'hidden',
    },
  };
};

const baseStyleLink = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    alignItems: 'center',
    borderRadius: 'full',
    borderStyle: 'solid',
    borderWidth: '3px',
    boxShadow: 'none',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    fontWeight: 'medium',
    height: '8',
    justifyContent: 'center',
    outline: 'none',
    textDecoration: 'none',
    width: '8',
    fontSize: 'xl',
    _focusVisible: {
      boxShadow: 'focusVisible',
    },
    '&[data-state="incomplete"]': {
      backgroundColor: 'white',
      borderColor: 'slate.100',
      color: 'slate.500',
    },
    '&[data-state="active"]': {
      backgroundColor: 'white',
      borderColor: `${c}.500`,
      color: `${c}.500`,
    },
    '&[data-state="complete"]': {
      backgroundColor: `${c}.500`,
      borderColor: `${c}.500`,
      color: 'white',
      '&:not([aria-readonly=true])': {
        _hover: {
          backgroundColor: `${c}.600`,
          borderColor: `${c}.600`,
        },
      },
    },
  };
};

const baseStyleGraph = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    '&[data-state="complete"]': {
      _before: {
        backgroundColor: `${c}.500`,
      },
      _after: {
        backgroundColor: `${c}.500`,
      },
    },
    '&[data-state="incomplete"]': {
      _before: {
        backgroundColor: 'slate.100',
      },
      _after: {
        backgroundColor: 'slate.100',
      },
    },
    '&[data-state="active"]': {
      _before: {
        backgroundColor: `${c}.500`,
      },
      _after: {
        backgroundColor: 'slate.100',
      },
    },
  };
};

const baseStyleLabel = (props: Dict) => {
  return {
    display: 'block',
    backgroundColor: 'none',
    color: 'slate.500',
    fontSize: 'xl',
  };
};

const baseStyleStep = (props: Dict) => {
  return { width: 'full' };
};

const baseStylePanel = (props: Dict) => {
  return {
    paddingTop: '4',
  };
};

const leftVariant = (props: Dict) => {
  return {
    ...containerAndLabelStyles,
    step: {
      marginStart: '2',
    },
    label: {
      marginTop: '4',
      paddingEnd: '2',
      textAlign: 'left',
    },
    graph: {
      _after: {
        borderRadius: '10px',
        marginLeft: '2',
        ...hGraph,
      },
    },
  };
};

const rightVariant = (props: Dict) => {
  return {
    ...containerAndLabelStyles,
    step: {
      marginEnd: '2',
    },
    label: {
      marginTop: '4',
      paddingStart: '2',
      textAlign: 'right',
    },
    graph: {
      _before: {
        borderRadius: '10px',
        marginRight: '2',
        ...hGraph,
      },
    },
  };
};

const horizontalCenterVariant = (props: Dict) => {
  return {
    ...containerAndLabelStyles,
    label: {
      marginTop: '4',
      paddingX: '2',
      textAlign: 'center',
    },
    graph: {
      _before: {
        borderRightRadius: '10px',
        marginRight: '2',
        ...hGraph,
      },
      _after: {
        borderLeftRadius: '10px',
        marginLeft: '2',
        ...hGraph,
      },
    },
  };
};

const verticalVariant = (props: Dict) => {
  return {
    container: {
      display: 'block',
      textAlign: 'left',
    },
    step: {
      alignItems: 'stretch',
      display: 'flex',
      width: 'auto',
      marginTop: '2',
    },
    label: {
      paddingTop: '1.5',
    },
    graph: {
      display: 'flex',
      flexDirection: 'column',
      _after: {
        ...vGraph,
      },
    },
  };
};

const variants = {
  horizontal: horizontalCenterVariant,
  vertical: verticalVariant,
  left: leftVariant,
  right: rightVariant,
  center: horizontalCenterVariant,
};

const baseStyle = (props: Dict) => ({
  container: baseStyleContainer(props),
  panel: baseStylePanel(props),
  link: baseStyleLink(props),
  step: baseStyleStep(props),
  graph: baseStyleGraph(props),
  label: baseStyleLabel(props),
});

const defaultProps = {
  colorScheme: 'light-blue',
  variant: 'horizontal',
};

export const Stepper = {
  parts,
  baseStyle,
  variants,
  defaultProps,
};
