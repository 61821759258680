import { IMenuButtonProps, MenuButton as MenuButtonInternal } from './MenuButton';
import { IMenuDividerProps, MenuDivider as MenuDividerInternal } from './MenuDivider';
import { IMenuGroupProps, MenuGroup as MenuGroupInternal } from './MenuGroup';
import { IMenuIconProps, MenuIcon as MenuIconInternal } from './MenuIcon';
import { IMenuItemProps, MenuItem as MenuItemInternal } from './MenuItem';
import { IMenuItemOptionProps, MenuItemOption as MenuItemOptionInternal } from './MenuItemOption';
import { IMenuListProps, MenuList as MenuListInternal } from './MenuList';
import {
  IMenuOptionGroupProps,
  MenuOptionGroup as MenuOptionGroupInternal,
} from './MenuOptionGroup';

export * from './Menu';
export {
  IMenuButtonProps,
  IMenuDividerProps,
  IMenuGroupProps,
  IMenuOptionGroupProps,
  IMenuIconProps,
  IMenuItemProps,
  IMenuItemOptionProps,
  IMenuListProps,
};

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `Menu.Button` instead
 */
export const MenuButton = MenuButtonInternal;
/**
 * @deprecated use `Menu.Divider` instead
 */
export const MenuDivider = MenuDividerInternal;
/**
 * @deprecated use `Menu.Group` instead
 */
export const MenuGroup = MenuGroupInternal;
/**
 * @deprecated use `Menu.Icon` instead
 */
export const MenuIcon = MenuIconInternal;
/**
 * @deprecated use `Menu.Item` instead
 */
export const MenuItem = MenuItemInternal;
/**
 * @deprecated use `Menu.ItemOption` instead
 */
export const MenuItemOption = MenuItemOptionInternal;
/**
 * @deprecated use `Menu.List` instead
 */
export const MenuList = MenuListInternal;
/**
 * @deprecated use `Menu.OptionGroup` instead
 */
export const MenuOptionGroup = MenuOptionGroupInternal;
