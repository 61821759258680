import { isDark, mode, randomColor } from '@chakra-ui/theme-tools';
import { sizes as themeSizes } from '../foundations/sizes';
import { Dict } from '../types';

const parts = ['container', 'excessLabel', 'badge', 'label'];

const baseStyleBadge = (props: Dict) => {
  return {
    transform: 'translate(25%, 25%)',
    borderRadius: 'full',
    borderStyle: 'solid',
    borderWidth: '0.125em',
    borderColor: mode('white', 'slate.600')(props),
    backgroundColor: 'success.400',
  };
};

const baseStyleExcessLabel = (props: Dict) => {
  return {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: mode('white', 'slate.600')(props),
    bg: mode('slate.200', 'slate.700')(props),
  };
};

const baseStyleContainer = (props: Dict) => {
  const { name, theme } = props;

  const bg = name
    ? randomColor({
        string: name,
        colors: [
          'turquoise.600',
          'light-blue.600',
          'dark-blue.600',
          'copper.600',
          'teal.600',
          'purple.600',
          'magenta.600',
        ],
      })
    : 'slate.100';

  const isBgDark = isDark(bg)(theme);

  let color = 'white';

  if (!isBgDark) {
    color = 'slate.900';
  }

  const borderColor = mode('white', 'slate.600')(props);

  return {
    bg,
    color,
    borderColor,
    verticalAlign: 'top',
    boxSizing: 'border-box',
  };
};

const baseStyle = (props: Dict) => ({
  badge: baseStyleBadge(props),
  excessLabel: baseStyleExcessLabel(props),
  container: baseStyleContainer(props),
});

type possibleSizes = typeof themeSizes;

const getSize = (size: keyof possibleSizes) => {
  const themeSize = themeSizes[size];

  return {
    container: {
      width: size,
      height: size,
      fontSize: `calc(${themeSize} / 2.5)`,
    },
    excessLabel: {
      width: size,
      height: size,
    },
    label: {
      fontSize: `calc(${themeSize} / 2.5)`,
      lineHeight: size !== 'full' ? themeSize : undefined,
    },
    badge: {
      width: '1.25em',
      height: '1.25em',
    },
  };
};

const sizes = {
  '2xs': getSize('4'),
  xs: getSize('6'),
  sm: getSize('8'),
  md: getSize('12'),
  lg: getSize('16'),
  xl: getSize('24'),
  '2xl': getSize('32'),
  full: getSize('full'),
};

const defaultProps = {
  size: 'md',
};

export const Avatar = {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
