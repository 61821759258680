import { mode, transparentize } from '@chakra-ui/theme-tools';
import { Dict } from '../types';

const parts = ['container', 'button', 'panel'];

const baseStyleContainer = (props: Dict) => {
  return {
    boxSizing: 'border-box',
    borderColor: mode('slate.100', 'slate.400')(props),
    _first: {
      borderTopWidth: '2px',
    },
    borderBottomWidth: '2px',
  };
};

const baseStyleButton = (props: Dict) => {
  const { theme } = props;

  return {
    textAlign: 'left',
    fontWeight: 'medium',
    _focusVisible: {
      boxShadow: 'focusVisible',
      zIndex: 'focus',
    },
    _hover: {
      bg: mode(
        transparentize('slate.900', 0.05)(theme),
        transparentize('slate.400', 0.05)(theme)
      )(props),
    },
    _disabled: {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  };
};

const baseStylePanel = {
  _focus: {
    outline: 'none',
  },
  pt: 2,
  px: 2,
  pb: 5,
};

const sizes = {
  sm: {
    button: {
      py: '1',
      px: '2',
      textStyle: 'md',
    },
    panel: {
      py: '1',
      px: '2',
      paddingBottom: '3', // 1 more than the horizontal
      textStyle: 'sm',
    },
  },
  md: {
    button: {
      py: '2',
      px: '3',
      textStyle: 'xl',
    },
    panel: {
      py: '2',
      px: '3',
      paddingBottom: '4', // 1 more than the horizontal
      textStyle: 'md',
    },
  },
  lg: {
    button: {
      py: '3',
      px: '4',
      textStyle: '2xl',
    },
    panel: {
      py: '3',
      px: '4',
      paddingBottom: '5', // 1 more than the horizontal
      textStyle: 'lg',
    },
  },
};

const baseStyle = (props: Dict) => ({
  container: baseStyleContainer(props),
  button: baseStyleButton(props),
  panel: baseStylePanel,
});

const defaultProps = {
  size: 'md',
};

export const Accordion = {
  parts,
  sizes,
  baseStyle,
  defaultProps,
};
