import { tokens } from '@lego/klik-ui-tokens';

export const colors = {
  // Special Cases for text-color
  transparent: 'transparent',
  currentColor: 'currentColor',
  white: '#ffffff',
  black: '#000000',

  ...tokens.colors,
};
