import { Text as TextComponent, TextProps } from '@chakra-ui/layout';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type ITextProps = TextProps;

export const Text = forwardRef<ITextProps, 'p'>(({ isTruncated, noOfLines, ...props }, ref) => {
  return (
    <TextComponent
      // always disable truncated if noOfLines is set
      isTruncated={noOfLines ? false : isTruncated}
      noOfLines={noOfLines}
      {...props}
      ref={ref}
    />
  );
});
