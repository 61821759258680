import { useModalContext } from '@chakra-ui/modal';
import { callAllHandlers } from '@chakra-ui/utils';
import { CloseButton, ICloseButtonProps } from '@lego/klik-ui-close-button';
import * as React from 'react';

export type IModalCloseButtonProps = ICloseButtonProps;

export const ModalCloseButton: React.FunctionComponent<IModalCloseButtonProps> = ({
  onClick,
  ...rest
}) => {
  const { onClose } = useModalContext();

  return (
    <CloseButton
      onClick={callAllHandlers(onClick, (event: React.MouseEvent) => {
        event.stopPropagation();
        onClose();
      })}
      position="absolute"
      right="0"
      top="0"
      {...rest}
    />
  );
};
