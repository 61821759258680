import {
  StatusErrorBold,
  StatusInformationBold,
  StatusSuccessBold,
  StatusWarningBold,
} from '@lego/klik-ui-icons';

export const StatusMap = {
  plain: {},
  info: { icon: StatusInformationBold, colorScheme: 'information' },
  warning: { icon: StatusWarningBold, colorScheme: 'warning' },
  success: { icon: StatusSuccessBold, colorScheme: 'success' },
  error: { icon: StatusErrorBold, colorScheme: 'error' },
};

export type IStatus = keyof typeof StatusMap;
