const baseStyle = {
  fontFamily: 'body',
};

const variants = {
  normal: {},
  'high-density': {},
};

const defaultProps = {
  variant: 'normal',
};

export const Text = {
  baseStyle,
  variants,
  defaultProps,
};
