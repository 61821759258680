import { IconButton as IconButtonComponent, IconButtonProps } from '@chakra-ui/button';
import { forwardRef } from '@chakra-ui/system';
import { dataAttr } from '@chakra-ui/utils';
import { SpinnerInline } from '@lego/klik-ui-spinner-inline';
import * as React from 'react';

export interface IIconButtonProps extends IconButtonProps {
  /**
   * When icons are fixed, this means that the icon will use the "small" icons, which are fixed,
   * irrespective of sm,md,lg sizes of the components
   */
  hasFixedIcon?: boolean;
}

export const IconButton = forwardRef<IIconButtonProps, 'button'>(
  ({ isRound = true, hasFixedIcon = false, ...rest }, ref) => {
    return (
      <IconButtonComponent
        borderRadius={isRound ? 'full' : 'base'}
        data-icon-fixed={dataAttr(hasFixedIcon)}
        data-icononly={true}
        tabIndex={0}
        {...rest}
        ref={ref}
        spinner={<SpinnerInline />}
      />
    );
  }
);
