import {
  InputElementProps,
  InputLeftElement as InputLeftElementComponent,
  InputRightElement as InputRightElementComponent,
} from '@chakra-ui/input';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type IInputElementProps = InputElementProps;

export const InputLeftElement = forwardRef<IInputElementProps, 'div'>(({ ...rest }, ref) => {
  return <InputLeftElementComponent {...rest} ref={ref} />;
});

InputLeftElement.id = 'InputLeftElement';

export const InputRightElement = forwardRef<IInputElementProps, 'div'>(({ ...rest }, ref) => {
  return <InputRightElementComponent {...rest} ref={ref} />;
});

InputRightElement.id = 'InputRightElement';
