const parts = ['container', 'field', 'button'];

const baseStyleContainer = {
  display: 'flex',
  alignItems: 'center',
};

const baseStyleField = {
  mx: '1',
};

const sizes = {
  sm: {
    field: {
      width: '10',
      px: '2',
    },
  },
  md: {
    field: {
      width: '14',
      px: '3.5',
      height: '48px',
    },
  },
  lg: {
    field: {
      width: '18',
      px: '5',
      height: '16',
    },
  },
};

const baseStyle = {
  container: baseStyleContainer,
  field: baseStyleField,
};

const variants = {
  plain: {},
  full: {
    field: {
      flex: 1,
    },
    button: {
      flex: 1,
    },
  },
};

const defaultProps = {
  size: 'md',
  variant: 'plain',
};

export const NumberInput = {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
