/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { mergeWith } from '@chakra-ui/utils';
import { Dict } from '../types';

const baseStyle = (props: Dict) => {
  const { isInDropArea } = props;

  let style = {
    alignItems: 'center',
    borderColor: 'slate.300',
    borderStyle: 'dashed',
    borderWidth: '2px',
    color: 'slate.500',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '100px',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    '> input': {
      cursor: 'pointer',
      height: '100%',
      opacity: 0,
      position: 'absolute',
      width: '100%',
    },
  };

  if (isInDropArea) {
    style = mergeWith(style, {
      borderColor: 'information.400',
    });
  }

  return style;
};

const defaultProps = {
  colorScheme: 'light-blue',
};

export const FileUploader = {
  baseStyle,
  defaultProps,
};
