import { mergeRefs } from '@chakra-ui/react-utils';
import {
  chakra,
  forwardRef,
  HTMLChakraProps,
  omitThemingProps,
  ThemingProps,
  useStyles,
} from '@chakra-ui/system';
import * as React from 'react';

export interface INavbarBrandProps extends HTMLChakraProps<'a'>, ThemingProps<'Navbar'> {
  onClick?(): void;
}

export const NavbarBrand = forwardRef<INavbarBrandProps, 'a'>((props, ref) => {
  const { ...rest } = omitThemingProps(props);
  const styles = useStyles();

  const brandRef = React.useRef<HTMLAnchorElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (!brandRef.current) {
      return;
    }

    switch (e.key) {
      case ' ':
      case 'Enter':
        e.preventDefault();
        brandRef.current.click();
    }
  };

  const finalRef = mergeRefs(brandRef, ref);

  // eslint-disable-next-line react/jsx-no-bind
  return <chakra.a __css={styles.brand} onKeyDown={handleKeyDown} ref={finalRef} {...rest} />;
});
