function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _inheritsLoose(subClass, superClass) { subClass.prototype = Object.create(superClass.prototype); subClass.prototype.constructor = subClass; _setPrototypeOf(subClass, superClass); }

function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }

import React from 'react';
import { Route } from 'react-router-dom';
import { getWithTransaction } from './get-with-transaction';

function getTransactionName(name, props) {
  var _props$match = props.match,
      match = _props$match === void 0 ? {} : _props$match;

  if (Array.isArray(name) && match.path) {
    return match.path;
  }

  return name;
}

function getApmRoute(apm) {
  var withTransaction = getWithTransaction(apm);
  return function (_React$Component) {
    _inheritsLoose(ApmRoute, _React$Component);

    function ApmRoute(props) {
      var _this;

      _this = _React$Component.call(this, props) || this;
      _this.state = {};
      return _this;
    }

    ApmRoute.getDerivedStateFromProps = function getDerivedStateFromProps(nextProps, prevState) {
      var initial = prevState.apmComponent == null;
      var path = nextProps.path,
          component = nextProps.component;
      var pathChanged = path != prevState.path;

      if (initial || pathChanged) {
        return {
          path: path,
          apmComponent: withTransaction(path, 'route-change', function (transaction, props) {
            if (transaction) {
              var name = getTransactionName(path, props);
              name && (transaction.name = name);
            }
          })(component)
        };
      }

      return null;
    };

    var _proto = ApmRoute.prototype;

    _proto.render = function render() {
      return React.createElement(Route, _extends({}, this.props, {
        component: this.state.apmComponent
      }));
    };

    return ApmRoute;
  }(React.Component);
}

export { getApmRoute };