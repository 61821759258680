import { chakra, HTMLChakraProps, useStyles } from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';
import { StatusMap } from '@lego/klik-ui-status-utils';
import * as React from 'react';
import { useInlineNotificationContext } from './InlineNotificationProvider';

export type IInlineNotificationStatusProps = HTMLChakraProps<'div'>;

export const InlineNotificationStatus: React.FunctionComponent<IInlineNotificationStatusProps> = (
  props
) => {
  const { status, hideIcon } = useInlineNotificationContext();

  const styles = useStyles();

  if (status === 'plain' || status === undefined) {
    return null;
  }

  if (hideIcon) {
    return null;
  }

  const { icon: BaseIcon } = StatusMap[status];

  return (
    <chakra.div
      display="inherit"
      {...props}
      __css={styles.status}
      className={cx('klik-inline-notification__status', props.className)}
    >
      <BaseIcon />
    </chakra.div>
  );
};
