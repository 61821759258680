import { Modal as ModalComponent, ModalProps } from '@chakra-ui/modal';
import { ThemingProps } from '@chakra-ui/system';
import { IStatus } from '@lego/klik-ui-status-utils';
import * as React from 'react';
import { ModalBody } from './ModalBody';
import { ModalCloseButton } from './ModalCloseButton';
import { ModalContent } from './ModalContent';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
import { ModalOverlay } from './ModalOverlay';
import { ModalProvider } from './ModalProvider';

interface IModalOptions {
  /**
   * The status of the Modal (`plain` (default), `success`, `info`, `warning` and `error`)
   */
  status?: IStatus;
  /**
   * With the status prop set, a corresponding icon is injected - unless explicitly hidden:
   */
  hideIcon?: boolean;
}

export interface IModalProps extends ModalProps, IModalOptions, ThemingProps<'Modal'> {}

type IModalComponent = React.FunctionComponent<IModalProps> & {
  Body: typeof ModalBody;
  CloseButton: typeof ModalCloseButton;
  Content: typeof ModalContent;
  Footer: typeof ModalFooter;
  Header: typeof ModalHeader;
  Overlay: typeof ModalOverlay;
};

export const Modal: IModalComponent = (props) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { size = '', variant, hideIcon = false } = props;

  return (
    <ModalProvider
      value={{
        size,
        status: variant as IStatus,
        hideIcon,
      }}
    >
      <ModalComponent {...props} />
    </ModalProvider>
  );
};

Modal.Body = ModalBody;
Modal.CloseButton = ModalCloseButton;
Modal.Footer = ModalFooter;
Modal.Header = ModalHeader;
Modal.Overlay = ModalOverlay;
Modal.Content = ModalContent;
