import { ITabProps, Tab as TabInternal } from './Tab';
import { ITabListProps, TabList as TabListInternal } from './TabList';
import { ITabPanelProps, TabPanel as TabPanelInternal } from './TabPanel';
import { ITabPanelsProps, TabPanels as TabPanelsInternal } from './TabPanels';

export * from './Tabs';
export { ITabProps, ITabListProps, ITabPanelProps, ITabPanelsProps };

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `Tabs.Tab` instead
 */
export const Tab = TabInternal;
/**
 * @deprecated use `Tabs.TabList` instead
 */
export const TabList = TabListInternal;
/**
 * @deprecated use `Tabs.TabPanel` instead
 */
export const TabPanel = TabPanelInternal;
/**
 * @deprecated use `Tabs.TabPanels` instead
 */
export const TabPanels = TabPanelsInternal;
