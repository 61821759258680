import { GridItem as GridItemInternal, IGridItemProps } from './GridItem';

export * from './Grid';
export { IGridItemProps };

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `Grid.Item` instead
 */
export const GridItem = GridItemInternal;
