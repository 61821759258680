import {
  chakra,
  forwardRef,
  HTMLChakraProps,
  omitThemingProps,
  ThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/system';
import { IconButton } from '@lego/klik-ui-icon-button';
import { MenuNavigationVertical } from '@lego/klik-ui-icons';
import * as React from 'react';
import { useNavbarContext } from './NavbarProvider';

interface INavbarBurgerOptions {
  iconColor?: string;
}

export interface INavbarBurgerProps
  extends HTMLChakraProps<'div'>,
    INavbarBurgerOptions,
    ThemingProps<'Navbar'> {}

export const NavbarBurger = forwardRef<INavbarBurgerProps, 'div'>((props, ref) => {
  const { ...rest } = omitThemingProps(props);

  const { orientation } = useNavbarContext();
  const { burger: styles } = useMultiStyleConfig('Navbar', { ...props, orientation });

  return (
    <chakra.div __css={styles} ref={ref} {...rest}>
      <IconButton
        aria-label="Menu"
        color={props.iconColor ?? 'white'}
        icon={<MenuNavigationVertical />}
        variant="ghost"
      />
    </chakra.div>
  );
});
