import { Checkbox as CheckboxComponent, CheckboxProps } from '@chakra-ui/checkbox';
import { forwardRef } from '@chakra-ui/system';
import { CheckTickBold, MinusBold } from '@lego/klik-ui-icons';
import * as React from 'react';

export type ICheckboxProps = CheckboxProps;

const CheckboxIcon: React.FunctionComponent<{ isIndeterminate?: boolean; isChecked?: boolean }> = (
  props
) => {
  const { isIndeterminate } = props;

  if (isIndeterminate) {
    return <MinusBold />;
  } else {
    return <CheckTickBold />;
  }
};

export const Checkbox = forwardRef<ICheckboxProps, 'div'>((props, ref) => {
  return <CheckboxComponent icon={<CheckboxIcon />} {...props} ref={ref} />;
});
