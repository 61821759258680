import {
  chakra,
  forwardRef,
  HTMLChakraProps,
  SystemStyleObject,
  useStyles,
} from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';
import * as React from 'react';

export type IInlineNotificationDescriptionProps = HTMLChakraProps<'div'>;

export const InlineNotificationDescription = forwardRef<IInlineNotificationDescriptionProps, 'div'>(
  (props, ref) => {
    const styles = useStyles();

    const descriptionStyles: SystemStyleObject = {
      display: 'inline',
      ...styles.description,
    };

    return (
      <chakra.div
        ref={ref}
        {...props}
        __css={descriptionStyles}
        className={cx('klik-inline-notification__desc', props.className)}
      />
    );
  }
);
