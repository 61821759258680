import {
  InputAddonProps,
  InputLeftAddon as InputLeftAddonComponent,
  InputRightAddon as InputRightAddonComponent,
} from '@chakra-ui/input';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type IInputAddonProps = InputAddonProps;

export const InputLeftAddon = forwardRef<IInputAddonProps, 'div'>(({ ...rest }, ref) => {
  return <InputLeftAddonComponent {...rest} ref={ref} />;
});

InputLeftAddon.id = 'InputLeftAddon';

export const InputRightAddon = forwardRef<IInputAddonProps, 'div'>(({ ...rest }, ref) => {
  return <InputRightAddonComponent {...rest} ref={ref} />;
});

InputRightAddon.id = 'InputRightAddon';
