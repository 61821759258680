import { mode, transparentize } from '@chakra-ui/theme-tools';
import { Dict } from '../types';

const parts = [
  'overlay',
  'dialogContainer',
  'dialog',
  'status',
  'header',
  'closeButton',
  'body',
  'footer',
  'icon',
  'outer',
];

const baseStyleOverlay = (props: Dict) => {
  const { colorScheme: c, theme } = props;

  return {
    backgroundColor: transparentize(`${c}.900`, 0.8)(theme),
    zIndex: 'modal',
  };
};

const baseStyleDialogContainer = (props: Dict) => {
  const { isCentered, scrollBehavior } = props;

  return {
    display: 'flex',
    zIndex: 'modal',
    justifyContent: 'center',
    alignItems: isCentered ? 'center' : 'flex-start',
    overflow: scrollBehavior === 'inside' ? 'hidden' : 'auto',
  };
};

const baseStyleDialog = (props: Dict) => {
  const { colorScheme: c, theme } = props;

  return {
    borderColor: transparentize(`${c}.900`, 0.3)(theme),
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 'sm',
    backgroundColor: mode('white', 'slate.600')(props),
    color: 'inherit',
    my: '3.75rem',
    mx: '1rem',
    zIndex: 'modal',
    boxShadow: '2',
  };
};

const baseStyleHeader = () => ({
  pl: 5,
  pt: 5,
  pr: 10,
  textStyle: 'h3',
  fontFamily: 'heading',
  fontWeight: 'medium',
});

const baseStyleCloseButton = () => ({
  position: 'absolute',
  right: 0,
  top: 0,
});

const baseStyleBody = (props: Dict) => {
  const { scrollBehavior } = props;

  return {
    px: 5,
    py: 3,
    flex: 1,
    textStyle: 'body1',
    overflow: scrollBehavior === 'inside' ? 'overlay' : undefined,

    // encapsulate content between horisontal lines if scroll=inside
    border: scrollBehavior === 'inside' ? '1px solid #ccc' : undefined,
    borderLeft: 'none',
    borderRight: 'none',
    marginRight: scrollBehavior === 'inside' ? '1rem' : undefined,
    marginLeft: scrollBehavior === 'inside' ? '1rem' : undefined,
    paddingLeft: scrollBehavior === 'inside' ? '0.3rem' : undefined,
    marginBottom: scrollBehavior === 'inside' ? '0.5rem' : undefined,
  };
};

const baseStyleFooter = () => ({
  px: 5,
  pb: 5,
  pt: 3,
});

const baseStyleStatus = () => {
  return {
    alignSelf: 'flex-start',
    marginStart: '3',
    display: 'inline',
    marginInlineStart: '5',
    marginTop: '6.5',
    // TODO: share a css var between this and ModalHeader to ensure equal fontSize
    fontSize: '1.25rem',
  };
};

const baseStyleOuter = (props: Dict) => {
  const { scrollBehavior } = props;

  return {
    maxH: scrollBehavior === 'inside' ? 'calc(100vh - 7.5rem)' : undefined,
  };
};

const baseStyle = (props: Dict) => ({
  overlay: baseStyleOverlay(props),
  dialogContainer: baseStyleDialogContainer(props),
  dialog: baseStyleDialog(props),
  header: baseStyleHeader(),
  closeButton: baseStyleCloseButton(),
  body: baseStyleBody(props),
  footer: baseStyleFooter(),
  status: baseStyleStatus(),

  outer: baseStyleOuter(props),
});

const getSize = (value: string) => {
  if (value === 'full') {
    return {
      dialog: { maxW: '100vw', h: '100vh', borderRadius: 'none', borderWidth: '0', my: 0, mx: 0 },
    };
  }

  return { dialog: { maxW: value } };
};

const sizes = {
  xs: getSize('xs'),
  sm: getSize('sm'),
  md: getSize('md'),
  lg: getSize('lg'),
  xl: getSize('xl'),
  '2xl': getSize('2xl'),
  '3xl': getSize('3xl'),
  '4xl': getSize('4xl'),
  '5xl': getSize('5xl'),
  '6xl': getSize('6xl'),
  full: getSize('full'),
};

const getStatus = (props: Dict) => {
  const { colorScheme: c } = props;

  const colorIndex = c === 'error' ? '500' : '400';

  return {
    ...props,
    ...baseStyle,
    status: {},
    dialog: {
      borderColor: `${c}.${colorIndex}`,
      borderLeftWidth: '0.75rem',
    },
  };
};

const variantPlain = {};

const variantSuccess = (props: Dict) => {
  return getStatus({ ...props, ...{ colorScheme: 'success' } });
};

const variantInfo = (props: Dict) => {
  return getStatus({ ...props, ...{ colorScheme: 'information' } });
};

const variantWarning = (props: Dict) => {
  return getStatus({ ...props, ...{ colorScheme: 'warning' } });
};

const variantError = (props: Dict) => {
  return getStatus({ ...props, ...{ colorScheme: 'error' } });
};

const variants = {
  plain: variantPlain,
  success: variantSuccess,
  info: variantInfo,
  warning: variantWarning,
  error: variantError,
};

const defaultProps = {
  size: 'md',
  variant: 'plain',
};

export const Modal = {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
