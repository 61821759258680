import { forwardRef, useStyles } from '@chakra-ui/system';
import { Button, IButtonProps } from '@lego/klik-ui-button';
import { MinusBold } from '@lego/klik-ui-icons';
import * as React from 'react';
import { useNumberInputContext } from './NumberInputProvider';

export type INumberDecrementProps = IButtonProps;

export const NumberDecrement = forwardRef<INumberDecrementProps, 'button'>(
  ({ variant = 'attached', ...rest }, ref) => {
    const styles = useStyles();
    const { getDecrementButtonProps, isDisabled } = useNumberInputContext();
    const decrement = getDecrementButtonProps(rest, ref);

    return (
      <Button
        {...decrement}
        ref={ref}
        sx={styles.button}
        tabIndex={isDisabled ? -1 : 0}
        variant={variant}
      >
        <MinusBold h="3" w="3" />
      </Button>
    );
  }
);
