import { ThemeComponentProps } from '@chakra-ui/theme';
import { mode } from '@chakra-ui/theme-tools';

const parts = ['text', 'icon'];

const baseStyleText = (props: ThemeComponentProps) => {
  return {
    color: mode('error.500', 'error.400')(props),
  };
};

const baseStyleIcon = (props: ThemeComponentProps) => {
  return {
    marginEnd: '0.5em',
    color: mode('error.500', 'error.400')(props),
  };
};

const baseStyle = (props: ThemeComponentProps) => ({
  text: baseStyleText(props),
  icon: baseStyleIcon(props),
});

const sizes = {
  sm: {
    text: {
      marginTop: '1',
      fontSize: 'xs',
    },
  },
  md: {
    text: {
      marginTop: '1',
      fontSize: 'sm',
    },
  },
  lg: {
    text: {
      marginTop: '2',
      fontSize: 'md',
    },
  },
};

const defaultProps = {
  size: 'md',
};

export const FormError = {
  parts,
  sizes,
  defaultProps,
  baseStyle,
};
