import { ModalFooter as ModalFooterComponent, ModalFooterProps } from '@chakra-ui/modal';
import { useMediaQuery } from '@chakra-ui/react';
import { forwardRef, SystemStyleObject } from '@chakra-ui/system';
import * as React from 'react';
import { useModalContext } from './ModalProvider';

export type IModalFooterProps = ModalFooterProps;

export const ModalFooter = forwardRef<IModalFooterProps, 'footer'>((props, ref) => {
  // Responsive layout: put the buttons in the button-group (footer) _below_ each other when
  // - screen width <= 416px
  // - size-prop is 'sm' or 'xs'
  const { size } = useModalContext();
  const hasSmallProp = size === 'xs' || size === 'sm';
  const [hasSmallScreen] = useMediaQuery('(max-width: 416px)'); // TRUE if EQUAL TO OR LESS than 416px
  const shouldRenderVertically = hasSmallScreen || hasSmallProp;

  const styles: SystemStyleObject = {
    '[role=group]': {
      flexFlow: shouldRenderVertically ? 'column' : 'row-reverse',
      width: shouldRenderVertically ? '100%' : '',
      gap: shouldRenderVertically ? '0.5em' : '',
    },
    '[role=group] button': {
      width: shouldRenderVertically ? '100%' : '',
    },
  };

  return <ModalFooterComponent {...props} ref={ref} sx={styles} />;
});
