import { DrawerBody as DrawerBodyInternal, IDrawerBodyProps } from './DrawerBody';
import {
  DrawerCloseButton as DrawerCloseButtonInternal,
  IDrawerCloseButtonProps,
} from './DrawerCloseButton';
import { DrawerContent as DrawerContentInternal, IDrawerContentProps } from './DrawerContent';
import { DrawerFooter as DrawerFooterInternal, IDrawerFooterProps } from './DrawerFooter';
import { DrawerHeader as DrawerHeaderInternal, IDrawerHeaderProps } from './DrawerHeader';
import { DrawerOverlay as DrawerOverlayInternal, IDrawerOverlayProps } from './DrawerOverlay';

export * from './Drawer';
export {
  IDrawerBodyProps,
  IDrawerFooterProps,
  IDrawerHeaderProps,
  IDrawerContentProps,
  IDrawerCloseButtonProps,
  IDrawerOverlayProps,
};

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `Drawer.Body` instead
 */
export const DrawerBody = DrawerBodyInternal;
/**
 * @deprecated use `Drawer.CloseButton` instead
 */
export const DrawerCloseButton = DrawerCloseButtonInternal;
/**
 * @deprecated use `Drawer.Content` instead
 */
export const DrawerContent = DrawerContentInternal;
/**
 * @deprecated use `Drawer.Footer` instead
 */
export const DrawerFooter = DrawerFooterInternal;
/**
 * @deprecated use `Drawer.Header` instead
 */
export const DrawerHeader = DrawerHeaderInternal;
/**
 * @deprecated use `Drawer.Overlay` instead
 */
export const DrawerOverlay = DrawerOverlayInternal;
