import { mode } from '@chakra-ui/theme-tools';
import { mergeWith } from '@chakra-ui/utils';
import { Dict } from '../types';
import { Link } from './link';

const parts = ['container', 'item', 'link', 'separator'];

const baseStyleContainer = {
  display: 'block',
};

const baseStyleLink = (props: Dict) => {
  const link = Link.baseStyle(props);

  return { ...mergeWith(link, Link.variants.standalone), fontWeight: 'medium' } as Dict;
};

const baseStyleSeparator = (props: Dict) => {
  return {
    color: mode('slate.400', 'slate.300')(props) as string,
    fontSize: 'xs',
  };
};

const sizes = {
  sm: {
    item: {
      textStyle: 'sm',
    },
    separator: {
      marginLeft: '0.5',
      marginRight: '1',
    },
  },
  md: {
    item: {
      textStyle: 'md',
    },
    separator: {
      marginLeft: '1',
      marginRight: '2',
    },
  },
  lg: {
    item: {
      textStyle: 'lg',
    },
    separator: {
      marginLeft: '2',
      marginRight: '3',
    },
  },
};

const baseStyle = (props: Dict): Dict => {
  return {
    link: baseStyleLink(props),
    separator: baseStyleSeparator(props),
    container: baseStyleContainer,
  };
};

const defaultProps = {
  size: 'md',
};

export const Breadcrumb = {
  parts,
  sizes,
  baseStyle,
  defaultProps,
};
