import { ITagCloseButtonProps, TagCloseButton as TagCloseButtonInternal } from './TagCloseButton';
import { ITagLabelProps, TagLabel as TagLabelInternal } from './TagLabel';

export * from './Tag';
export { ITagCloseButtonProps, ITagLabelProps };

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `Tag.CloseButton` instead
 */
export const TagCloseButton = TagCloseButtonInternal;
/**
 * @deprecated use `Tag.Label` instead
 */
export const TagLabel = TagLabelInternal;
