import { ToastId, useToast as useChakraToast, UseToastOptions } from '@chakra-ui/toast';

export type CustomUseToastOptions = Pick<
  UseToastOptions,
  'duration' | 'id' | 'onCloseComplete' | 'position' | 'render'
>;

export type UseToast = (options?: CustomUseToastOptions) => {
  (options?: CustomUseToastOptions | undefined): number | string | undefined;
  close: (id: ToastId) => void;
  isActive: (id: ToastId) => boolean | undefined;
};

export const useToast = useChakraToast as UseToast;
